import React, { FC, lazy, Suspense, PropsWithChildren } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from 'components/Layout';
import { RoutePaths } from './RoutePaths';
import { LoadingPage } from 'components/common/Loading';
import { ProtectedRoute } from './ProtectedRoute';
import { useHasAccess, Permission } from 'services/RightsService';

const HomePage = lazy(() => import('components/Pages/Home/HomePage'));
const DisclaimerMappingPage = lazy(() => import('components/Pages/Disclaimer/DisclaimerMappingPage'));
const NotFound = lazy(() => import('components/Pages/Error/NotFound'));
const NotAuthorized = lazy(() => import('components/Pages/Error/NotAuthorized'));

export const AppRoutes: FC = () => {
    return <Layout>
        <Suspense fallback={<LoadingPage />}>
            <ProtectedRoute>
                <Routes>
                    <Route path={RoutePaths.Home.route} element={<HomePage />} />
                    <Route
                        path={RoutePaths.DisclaimerMapping.List.route}
                        element={<ProtectedPage permission={Permission.Disclaimer}><DisclaimerMappingPage /></ProtectedPage>}
                    />
                    <Route
                        path={RoutePaths.DisclaimerMapping.Edit.route}
                        element={<ProtectedPage permission={Permission.Disclaimer}><DisclaimerMappingPage /></ProtectedPage>}
                    />
                    <Route path={RoutePaths.Errors.NotFound.route} element={<NotFound />} />
                </Routes>
            </ProtectedRoute>
        </Suspense>
    </Layout>;
};

interface IProtectedPageProps extends PropsWithChildren {
    permission: Permission;
}

const ProtectedPage: FC<IProtectedPageProps> = ({ permission, children }) => {
    const { hasAnyRight } = useHasAccess();
    const hasPermission = hasAnyRight(permission);
    return hasPermission ? children : <NotAuthorized />;
};
