import { useAppContext } from 'components/common/AppProvider';
import { BackofficeApplicationContext } from './ApiService/ServiceBoard/ServiceBoardApiClient';
import { ServiceBoardSelectors } from 'store/Normalizr/ServiceBoardSelectors';

export enum Permission {
    Admin = 'ADMIN',
    Disclaimer = 'DISCLAIMER_MANAGEMENT',
}

const authorizedPermissions = [Permission.Admin, Permission.Disclaimer];

export const useHasAccess = () => {
    const { state: { applicationContext: { data, isFetching }, entities } } = useAppContext();
    const applicationContext = ServiceBoardSelectors.getBackofficeApplicationContext(data, entities.serviceBoard);

    return {
        hasAccess: hasAccess(applicationContext),
        hasAnyRight: (rights: Permission | Permission[]) => hasAnyRight(applicationContext, rights),
        isFetchingOrUnloaded: !data || isFetching,
    };
};

const hasAccess = (context: BackofficeApplicationContext | null | undefined): boolean => {
    const rights = authorizedPermissions.map(x => x.toString());
    return context?.permissions?.some((p) => rights.includes(p)) || false;
};

const hasAnyRight = (context: BackofficeApplicationContext | null | undefined, rights: Permission | Permission[]): boolean => {
    const arrayRights = (Array.isArray(rights) ? rights : [rights]).map(x => x.toString());
    return context?.permissions?.some((p) => arrayRights.includes(p)) || false;
};
