import React, { FC } from 'react';

export const LoadingPage: FC = () => {
    return (
        <div className="service-loader d-flex flex-column">
            <div className="service-loader-logo"></div>
            <div className="progress progress-sm progress-container">
                <div className="progress-bar animated-progress" role="progressbar"></div>
            </div>
        </div>
    );
};