import React, { createContext, useContext, PropsWithChildren, useRef } from 'react';

export interface IDebouncerContext {
    debounce: <T, V>(debounceKey: string, arg: T, func: (args: T[]) => Promise<V>, delay?: number) => Promise<V>;
}

export const DebouncerContext = createContext<IDebouncerContext>({
    debounce: () => Promise.resolve({} as any),
});

export const useDebouncerContext = () => useContext(DebouncerContext);

interface IDebouncer {
    args: any[];
    timer?: ReturnType<typeof setTimeout>;
    promise?: Promise<any>;
    resolve?: (value: any) => void;
    reject?: (reason: any) => void;
}
interface ITypedDebouncer<T, V> {
    args: T[];
    timer?: ReturnType<typeof setTimeout>;
    promise?: Promise<V>;
    resolve?: (value: Awaited<V>) => void;
    reject?: (reason: any) => void;
}

export const DebouncerProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const debouncesRef = useRef<Record<string, IDebouncer>>({});

    const get = <T, V>(debounceKey: string) => {
        debouncesRef.current[debounceKey] ??= { args: [] };
        return debouncesRef.current[debounceKey] as ITypedDebouncer<T, V>;
    };

    const clear = (debounceKey: string) => {
        debouncesRef.current[debounceKey] = { args: [] };
    };

    const initPromise = (debounceKey: string) => {
        debouncesRef.current[debounceKey] ??= { args: [] };
        const debounceData = debouncesRef.current[debounceKey];
        debounceData.promise = new Promise((resolve, reject) => {
            debounceData.resolve = resolve;
            debounceData.reject = reject;
        });
    };

    const debounce = <T, V>(debounceKey: string, arg: T, func: (args: T[]) => Promise<V>, delay = 50) => {
        get(debounceKey).args.push(arg);

        if (get(debounceKey).timer) {
            clearTimeout(get(debounceKey).timer);
        }

        if (!get(debounceKey).promise) {
            initPromise(debounceKey);
        }

        const newTimer = setTimeout(async () => {
            const debounceData = get<T, V>(debounceKey);
            clear(debounceKey);
            try {
                const data = await func(debounceData.args);
                debounceData.resolve && debounceData.resolve(data);
            } catch (e) {
                debounceData.reject && debounceData.reject(e);
            }
        }, delay);

        get(debounceKey).timer = newTimer;

        return get<T, V>(debounceKey).promise!;
    };

    const context = {
        debounce,
    };

    return (
        <DebouncerContext.Provider value={context}>
            {children}
        </DebouncerContext.Provider>
    );
};