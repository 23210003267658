import { normalize } from 'normalizr';
import { ThunkAction } from 'components/common/AppProvider';
import { ApplicationsApi, ServiceBoardApiSchema } from 'services/ApiService';
import { logError } from 'services/logger';
import { mergeServiceBoardEntities } from 'store/Normalizr/NormalizrAction';
import {
    createFetchApplicationContextAction,
    createFetchApplicationContextSuccessAction,
    createFetchApplicationContextFailureAction,
} from './ApplicationContextActions';

export const getApplicationContext = (): ThunkAction => async (dispatch, getState) => {
    const { applicationContext } = getState();
    if (applicationContext && (applicationContext.isFetching || !applicationContext.didInvalidate)) {
        return;
    }

    try {
        dispatch(createFetchApplicationContextAction());

        const data = await ApplicationsApi.getBackofficeContext();
        const normalizedData = normalize(data, ServiceBoardApiSchema.BackofficeApplicationContextSchema);
        dispatch(mergeServiceBoardEntities(normalizedData.entities));
        dispatch(createFetchApplicationContextSuccessAction(normalizedData.result));
    } catch (error) {
        dispatch(createFetchApplicationContextFailureAction());
        logError(error);
        throw error;
    }
};
