import React, { FC } from 'react';

const mailTo = 'sgmarkets@sgcib.com';

type ErrorCode = 403 | 404;

type ErrorPageProps = {
  errorCode: ErrorCode,
};

const titles: Record<ErrorCode, string> = {
  403: 'Forbidden.',
  404: 'Page not found.',
};

const subTitles: Record<ErrorCode, string> = {
  403: 'Sorry, you don\'t have the rights to access this page.',
  404: 'Sorry, we couldn\'t find this page.',
};

const ErrorPage: FC<ErrorPageProps> = ({ errorCode }) => {
  const handleTryAgainClick = () => {
    window.location.reload();
  };

  return <>
    <style>
      {`
      body {
        width: 100vw;
        height: 100vh;
        margin: 0;
      }

      @media (min-width: 992px) {
        .section-1 {
          background: linear-gradient(
            90deg,
            #fff 50%,
            rgba(250, 250, 250, 1) 0
          );
        }

        .section-2,
        .section-3 {
          background-size: 6px 6px;
          background-image: linear-gradient(
            -45deg,
            #fafafa 46%,
            rgba(48, 51, 51, 0.7) 49%,
            rgba(48, 51, 51, 0.7) 51%,
            #fafafa 55%
          );
        }

        .section-2 > .container-fluid {
           background: linear-gradient(
            90deg,
            #ffffff 50%,
            rgba(250, 250, 250, 0) 0
          ); 
        }

        .section-3 > .container-fluid {
          background: linear-gradient(
            90deg,
            #fafafa 50%,
            rgba(250, 250, 250, 0) 0
          );
        }
      }

      .text-outline-socgen {
        text-shadow: -1px 0 #e60028, 0 1px #e60028, 1px 0 #e60028,
          0 -1px #e60028;
      }

      @media (max-width: 992px) {
        .main-wrapper {
          max-width: 350px;
        }
      }
      `}
    </style>
    <section className="section-2 bg-white">
      <div className="container-fluid">
        <div className="container main-wrapper">
          <div className="row bg-white">
            <div className="col-lg-3 pt-5">
              <span className="d-block d-md-none text-outline-socgen text-primary-alt fw-medium" style={{
                fontSize: 56,
              }}>{errorCode}</span>

              <span className="d-none d-md-block d-lg-none text-outline-socgen text-primary-alt fw-medium" style={{
                lineHeight: 1.2,
                fontSize: 56,
              }}>{errorCode}</span>

              <span className="d-none d-lg-inline-block d-xl-none display-2 text-outline-socgen text-primary-alt fw-medium text-center">{errorCode}</span>

              <span className="d-none d-xl-block display-1 text-outline-socgen text-primary-alt fw-medium text-center" style={{ fontSize: 96 }}>{errorCode}</span>
            </div>
            <div className="col-lg-5 d-flex flex-column justify-content-between">
              <div>
                <h1 className="d-none d-lg-block display-2 fw-medium line-height-1 pt-5 pb-3">
                  {titles[errorCode]}
                </h1>
                <h1 className="d-lg-none display-3 fw-medium line-height-1 pt-5 pb-2">
                  {titles[errorCode]}
                </h1>

                {/* MOBILE */}
                <p className="d-lg-none mb-5 h3 fw-medium text-secondary line-height-1">
                  {subTitles[errorCode]}
                </p>

                {/* DESKTOP */}
                <p className="d-none d-lg-block d-xxl-none display-4 fw-medium text-secondary w-75 line-height-1">
                  {subTitles[errorCode]}
                </p>

                {/* XXL */}
                <p className="d-none d-xxl-block display-3 fw-medium text-secondary w-75 line-height-1">
                  {subTitles[errorCode]}
                </p>
              </div>
              <div className="mb-5 d-none d-md-flex">
                <button className="btn btn-xl btn-primary" onClick={handleTryAgainClick}>Try again</button>
                <a className="btn btn-xl btn-flat-secondary ms-1" href={`mailto:${mailTo}`}>
                  Contact us
                </a>
              </div>
              <div className="d-block d-md-none">
                <button className="btn btn-xl btn-primary btn-block mb-1" onClick={handleTryAgainClick}>
                  Try again
                </button>
                <a className="btn btn-xl btn-flat-secondary ms-1 btn-block mb-5" href={`mailto:${mailTo}`}>
                  Contact us
                </a>
              </div>
            </div>
            <div className="col-4 px-0 position-relative">
              <div className="d-none d-lg-block" style={{
                paddingTop: '140%',
                backgroundImage: 'url(https://shared.fr.world.socgen/error/404.svg)',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>;
};

export default ErrorPage;