import React from 'react';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'sgwt-account-center': any;
        }
    }
}

export const AccountCenter: React.FC = () => {
    return (
        <sgwt-account-center
            id="sgwtAccountCenter"
            language="en"
            authentication="sg-connect-v2"
            mode="sg-markets"
        />
    );
};
