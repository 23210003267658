import { ApiRequest, Reducer } from 'store';

export const initialState: ApiRequest<string> = {
    isFetching: false,
    didInvalidate: true,
} as const;

export type ApplicationContextState = typeof initialState;

export const ApplicationContextReducer: Reducer<ApplicationContextState> = (state = initialState, action) => {
    switch (action.type) {
        case '@APPLICATION_CONTEXT/FETCH_APPLICATION_CONTEXT':
            return {
                ...state,
                didInvalidate: true,
                isFetching: true,
            };

        case '@APPLICATION_CONTEXT/FETCH_APPLICATION_CONTEXT_SUCCESS':
            return {
                ...state,
                didInvalidate: false,
                isFetching: false,
                data: action.payload.loggedUserIcId || undefined,
            };

        case '@APPLICATION_CONTEXT/FETCH_APPLICATION_CONTEXT_FAILURE':
            return {
                ...state,
                didInvalidate: true,
                isFetching: false,
            };

        default:
            return state;
    }
};
