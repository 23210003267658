import React, { FC } from 'react';
import { HeaderLogo } from './HeaderLogo';
import SgMarketLogo from 'images/sg_market.svg';
import { AccountCenter } from './AccountCenter';
import { HeaderNavigation } from './HeaderNavigation';
import { HelpCenter } from './HelpCenter';

export const Header: FC = () => {
    return <>
        <header className="sticky-top">
            <nav className="navbar navbar-header navbar-expand-lg navbar-light bg-lvl1 px-3 border-bottom">

                <HeaderLogo />

                <HeaderNavigation />

                <div className="d-block flex-grow-1"></div>

                <AccountCenter />

                <svg className="overflow-visible ms-3 d-none d-sm-block" width="175" height="32">
                    <image xlinkHref={SgMarketLogo} x="0" y="0" height="16" width="145"></image>
                    <text className="fw-medium" x="5" y="32" height="16" width="145">
                        My Services Backoffice
                    </text>
                </svg>
            </nav>
        </header>
        <HelpCenter />
    </>;
};
