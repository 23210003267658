import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'bootstrap';
import { AppProvider } from 'components/common/AppProvider';
import { SgwtProvider } from 'components/common/SgwtProvider';
import { DebouncerProvider } from 'components/common/DebouncerProvider';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from 'routes';

import './css/bootstrapTheming.scss';
import './css/site.scss';

async function start() {
    const App = () => (
        <SgwtProvider>
            <AppProvider>
                <DebouncerProvider>
                    <BrowserRouter>
                        <AppRoutes />
                    </BrowserRouter>
                </DebouncerProvider>
            </AppProvider>
        </SgwtProvider>
    );

    const renderApp = () => {
        const container = document.getElementById('root');

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const root = createRoot(container!);
        root.render(App());
        const appInitLoader = document.getElementById('app-init-loader');
        if (appInitLoader) {
            appInitLoader.remove();
        }
    };

    renderApp();
}

start();
