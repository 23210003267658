import { ApiRequest, Reducer } from 'store';

export const initialState: {
    search: { searchTerm: string } & ApiRequest<string[]>,
    byKey: { [serviceKey: string]: ApiRequest<string> },
} = {
    search: {
        searchTerm: '',
        isFetching: false,
        didInvalidate: true,
    },
    byKey: {},
} as const;

export type SgmServiceState = typeof initialState;

export const SgmServiceReducer: Reducer<SgmServiceState> = (state = initialState, action) => {
    switch (action.type) {
        case '@SGM_SERVICES/FETCH_SEARCH':
            return {
                ...state,
                search: {
                    ...state.search,
                    didInvalidate: false,
                    isFetching: true,
                    searchTerm: action.payload.searchTerm,
                },
            };

        case '@SGM_SERVICES/FETCH_SEARCH_SUCCESS':
            if (state.search.searchTerm?.toLowerCase() !== action.payload.searchTerm?.toLowerCase()) {
                // Nothing to do, another resquest is in progress
                return state;
            }

            return {
                ...state,
                search: {
                    ...state.search,
                    didInvalidate: false,
                    isFetching: false,
                    data: action.payload.data,
                },
            };

        case '@SGM_SERVICES/FETCH_SEARCH_FAILURE':
            if (state.search.searchTerm?.toLowerCase() !== action.payload.searchTerm?.toLowerCase()) {
                // Nothing to do, another resquest is in progress
                return state;
            }

            return {
                ...state,
                search: {
                    ...state.search,
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@SGM_SERVICES/FETCH_SEARCH_BY_KEY':
            return {
                ...state,
                byKey: {
                    ...state.byKey,
                    [action.payload.serviceKey]: {
                        didInvalidate: false,
                        isFetching: true,
                    },
                },
            };

        case '@SGM_SERVICES/FETCH_SEARCH_BY_KEY_SUCCESS':
            return {
                ...state,
                byKey: {
                    ...state.byKey,
                    [action.payload.serviceKey]: {
                        didInvalidate: false,
                        isFetching: false,
                    },
                },
            };

        case '@SGM_SERVICES/FETCH_SEARCH_BY_KEY_FAILURE':
            return {
                ...state,
                byKey: {
                    ...state.byKey,
                    [action.payload.serviceKey]: {
                        didInvalidate: true,
                        isFetching: false,
                    },
                },
            };

        default:
            return state;
    }
};
