import React from 'react';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'sgwt-help-center': any;
        }
    }
}

export const HelpCenter: React.FC = () => {
    return (
        <sgwt-help-center
            id="sgwtSuperHelpCenter"
            sg-connect-support="sg-connect-v2"
            application-id="service-board-backoffice"
        />
    );
};
