import React, { FC, MouseEvent } from 'react';
import { RoutePaths } from 'routes/RoutePaths';
import { useNavigate, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useHasAccess, Permission } from 'services/RightsService';

export const HeaderNavigation: FC = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { hasAnyRight } = useHasAccess();

    const handleClick = (event: MouseEvent, url: string) => {
        event.preventDefault();
        navigate(url);
    };

    return <>
        <ul className="navbar-navigation m-0">
            <li className="navbar-item">
                <a
                    className={classNames('navbar-link', { 'active': pathname === RoutePaths.Home.url() })}
                    aria-current="page"
                    href={RoutePaths.Home.url()}
                    onClick={(evt) => handleClick(evt, RoutePaths.Home.url())}
                >
                    Home
                </a>
            </li>
            {hasAnyRight(Permission.Disclaimer) &&
                <li className="navbar-item">
                    <a
                        className={classNames('navbar-link', { 'active': pathname.startsWith(RoutePaths.DisclaimerMapping.List.url()) })}
                        aria-current="page"
                        href={RoutePaths.DisclaimerMapping.List.url()}
                        onClick={(evt) => handleClick(evt, RoutePaths.DisclaimerMapping.List.url())}
                    >
                        Disclaimer
                    </a>
                </li>}
        </ul>
    </>;
};