import React, { FC } from 'react';
import { ToastContentProps } from 'react-toastify';
import { formatTimeSec } from 'services/date';

export type ToastMessageProps = Partial<ToastContentProps> & {
    title?: string;
    message?: string;
};

export const ToastMessage: FC<ToastMessageProps> = ({ title, message, closeToast, toastProps }) => {
    let toastTitle = title;
    if (!title) {
        switch (toastProps?.type) {
            case 'error':
                toastTitle = 'Error';
                break;

            case 'warning':
                toastTitle = 'Warning';
                break;

            case 'success':
                toastTitle = 'Success';
                break;

            default:
                toastTitle = 'Information';
                break;
        }
    }

    return (
        <>
            <div className="toast-header">
                <strong>{toastTitle}</strong>
                <div className="flex-grow-1" />
                <button type="button" className="btn btn-flat btn-sm" data-dismiss="toast" aria-label="Close" onClick={closeToast}>
                    <i className="icon">close</i>
                </button>
            </div>
            {message && <div className="toast-body text-primary">{message}</div>}
            <div className="toast-footer ms-1 p-1">
                <span className="text-secondary font-weight-medium">
                    {formatTimeSec(new Date())}
                </span>
            </div>
        </>
    );
};
