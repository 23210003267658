import { schema } from 'normalizr';
import { IServiceBoardEntities, ISgmSearchEntities } from 'services/ApiService';

const createNormalizrPayload = (entitySchema: schema.Entity<any>, entityIds: (string | number)[]) => {
    return {
        [entitySchema.key]: entityIds ? entityIds.reduce((prev, id) => {
            prev[id] = null;
            return prev;
        }, {} as { [id: string]: null; }) : null,
    };
};

const mergeEntities = <T, TU extends string>(type: TU) => (payload: T) => {
    return {
        type,
        payload,
    } as const;
};

const removeEntities = <TU extends string>(type: TU) => (entitySchema: schema.Entity, entityIds: Array<string | number>) => {
    const payload = createNormalizrPayload(entitySchema, entityIds);
    return {
        type,
        payload,
    } as const;
};

export const mergeServiceBoardEntities = mergeEntities<IServiceBoardEntities, '@NORMALIZR/MERGE_SERVICE_BOARD_ENTITIES'>('@NORMALIZR/MERGE_SERVICE_BOARD_ENTITIES');
export const removeServiceBoardEntities = removeEntities<'@NORMALIZR/REMOVE_SERVICE_BOARD_ENTITIES'>('@NORMALIZR/REMOVE_SERVICE_BOARD_ENTITIES');

export const mergeSgmSearchEntities = mergeEntities<ISgmSearchEntities, '@NORMALIZR/MERGE_SGM_SEARCH_ENTITIES'>('@NORMALIZR/MERGE_SGM_SEARCH_ENTITIES');
export const removeSgmSearchEntities = removeEntities<'@NORMALIZR/REMOVE_SGM_SEARCH_ENTITIES'>('@NORMALIZR/REMOVE_SGM_SEARCH_ENTITIES');

export type NormalizrAction =
    | ReturnType<typeof mergeServiceBoardEntities>
    | ReturnType<typeof removeServiceBoardEntities>
    | ReturnType<typeof mergeSgmSearchEntities>
    | ReturnType<typeof removeSgmSearchEntities>;
