import { ApiRequest, Reducer } from 'store';

export const initialState: {
    questionMappings: ApiRequest<string[]>,
    isSaving: boolean,
    isDeleting: boolean,
} = {
    questionMappings: {
        isFetching: false,
        didInvalidate: true,
    },
    isSaving: false,
    isDeleting: false,
} as const;

export type DisclaimerState = typeof initialState;

export const DisclaimerReducer: Reducer<DisclaimerState> = (state = initialState, action) => {
    switch (action.type) {
        case '@DISCLAIMER/FETCH_QUESTION_MAPPINGS':
            return {
                ...state,
                questionMappings: {
                    ...state.questionMappings,
                    didInvalidate: true,
                    isFetching: true,
                },
            };

        case '@DISCLAIMER/FETCH_QUESTION_MAPPINGS_SUCCESS':
            return {
                ...state,
                questionMappings: {
                    ...state.questionMappings,
                    didInvalidate: false,
                    isFetching: false,
                    data: action.payload.data,
                },
            };

        case '@DISCLAIMER/FETCH_QUESTION_MAPPINGS_FAILURE':
            return {
                ...state,
                questionMappings: {
                    ...state.questionMappings,
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@DISCLAIMER/PUT_QUESTION_MAPPING':
            return {
                ...state,
                isSaving: true,
            };

        case '@DISCLAIMER/PUT_QUESTION_MAPPING_SUCCESS':
            return {
                ...state,
                isSaving: false,
                questionMappings: {
                    ...state.questionMappings,
                    didInvalidate: true,
                },
            };

        case '@DISCLAIMER/PUT_QUESTION_MAPPING_FAILURE':
            return {
                ...state,
                isSaving: false,
            };

        case '@DISCLAIMER/DELETE_QUESTION_MAPPING':
            return {
                ...state,
                isDeleting: true,
            };

        case '@DISCLAIMER/DELETE_QUESTION_MAPPING_SUCCESS':
            return {
                ...state,
                isDeleting: false,
                questionMappings: {
                    ...state.questionMappings,
                    didInvalidate: true,
                },
            };

        case '@DISCLAIMER/DELETE_QUESTION_MAPPING_FAILURE':
            return {
                ...state,
                isDeleting: false,
            };

        default:
            return state;
    }
};
