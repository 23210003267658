import { BackofficeApplicationContext, DisclaimerDocGenQuestionMapping } from './ServiceBoardApiClient';
import { ObjectsWithoutIdProperty, ObjectsWithoutIdPropertyEnum } from './ServiceBoardApiSchema';

export class BaseServiceBoardApiSchema {
    protected static getId(value: ObjectsWithoutIdProperty, type: ObjectsWithoutIdPropertyEnum): string {
        switch (type) {
            case ObjectsWithoutIdPropertyEnum.BackofficeApplicationContext:
                return (value as BackofficeApplicationContext).icId || '';
            case ObjectsWithoutIdPropertyEnum.DisclaimerDocGenQuestionMapping:
                return (value as DisclaimerDocGenQuestionMapping).questionId?.toString() || '';
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const exhaustiveCheck: never = type;
        }
        return '';
    }
}
