export const createFetchApplicationContextAction = () => {
    return {
        type: '@APPLICATION_CONTEXT/FETCH_APPLICATION_CONTEXT',
    } as const;
};

export const createFetchApplicationContextSuccessAction = (loggedUserIcId?: string | null) => {
    return {
        type: '@APPLICATION_CONTEXT/FETCH_APPLICATION_CONTEXT_SUCCESS',
        payload: {
            loggedUserIcId,
        },
    } as const;
};

export const createFetchApplicationContextFailureAction = () => {
    return {
        type: '@APPLICATION_CONTEXT/FETCH_APPLICATION_CONTEXT_FAILURE',
    } as const;
};

export type ApplicationContextAction =
    | ReturnType<typeof createFetchApplicationContextAction>
    | ReturnType<typeof createFetchApplicationContextSuccessAction>
    | ReturnType<typeof createFetchApplicationContextFailureAction>;
