import React, { FC } from 'react';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'sgwt-mini-footer': {
                'id': string;
                'no-border': boolean;
                'mode': string;
                'contact-us': string;
            };
        }
    }
}

export const Footer: FC = () => {
    return (
        <div className="footer w-100 d-flex border-top bg-lvl1">
            <div className="flex-grow-1">
                <sgwt-mini-footer
                    id="sgm-mini-footer"
                    no-border
                    mode="sg-markets"
                    contact-us="{'mail': 'itec-support-sgr.par@sgcib.com'}"
                />
            </div>
        </div>
    );
};
